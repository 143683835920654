import gql from 'graphql-tag';

export const getQuizListing = gql`query($defaultLanguage: String!) {
    getQuizListing(first: 1, defaultLanguage: $defaultLanguage) {
        edges {
            node {
                id,
                title,
                description,
                image {
                    fullpath
                },
                resultScores {
                    ...on fieldcollection_QuizResult {
                        scoreFrom,
                        scoreTo,
                        resultText,
                        resultImage {
                            fullpath(thumbnail: "homepageImage")
                        }
                    }
                },
                questions {
                    ... on object_QuizQuestion {
                        Question,
                        Description,
                        anwsers {
                            ... on fieldcollection_QuizAnswer {
                                answer,
                                correctAnswer,
                                uniqueId
                            }
                        }
                    }
                }
            }
        }
    }
}`;
