<template>
    <div class="progress-bar progress-bar--quiz">
        <div class="progress-bar__wrapper">
            <div v-for="question in totalCount" 
                :key="question" 
                :class="['progress-bar__item', {'progress-bar__item--active': (question - 1) == current}]"
            >
                <div class="progress-bar__number">{{ question }}</div>
                <div class="progress-bar__line"></div>
            </div>
        </div>

        <div class="progress-bar__wrapper progress-bar__wrapper--mobile">
            {{ questionText }} {{ (current + 1) }}/{{ totalCount }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: ['totalCount', 'current', 'questionText']
}
</script>
