import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    cache,
    uri: `${process.env.VUE_APP_APOLLO_API_URI}/pimcore-graphql-webservices/api?apikey=${process.env.VUE_APP_APOLLO_API_KEY}`,
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

export default apolloProvider;
