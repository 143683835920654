<template>
    <template v-if="!isDataLoading">
        <template v-if="isLoading">
            <loading-spinner :loadingText="loadingText"></loading-spinner>
        </template>

        <div v-else class="quiz__results">
            <h2 class="quiz__score">{{ score }}/{{ total }}</h2>
            <p class="quiz__score-text">{{ correctAnswearsText }}</p>
            <p class="quiz__text">{{ currentText }}</p>
            
            <template v-if="currentImage">
                <img :src="currentImage" :alt="currentText" class="quiz__img" />
            </template>

            <div class="btn-wrapper">
                <button @click="tryAgain" class="btn btn--primary">
                    {{ tryAgainText }}
                </button>
            </div>
        </div>
    </template>
</template>

<script>
import LoadingSpinner from './loading-spinner.vue';

export default {
    name: 'QuizResult',
    components: {
        LoadingSpinner
    },
    props: [
        'score', 
        'total', 
        'isDataLoading', 
        'loadingText', 
        'tryAgainText', 
        'correctAnswearsText', 
        'resultScores'
    ],
    emits: ['reset'],
    data() {
        return {
            isLoading: true,
            currentText: null,
            currentImage: null
        }
    },
    methods: {
        calculateScore() {
            let score = Math.round((this.score / this.total) * 100);
            score = score == 0 ? 1 : score;
            const result = this.resultScores.filter(result => score >= result.scoreFrom && score <= result.scoreTo)
        
            this.currentText = result[0].resultText;
            this.currentImage = result[0].resultImage ? result[0].resultImage.fullpath : null;
        },
        tryAgain() {
            this.$emit('reset');
        }
    },
    mounted() {
        this.calculateScore();

        setTimeout(() => {
            this.isLoading = false;
        }, 3000);
    }
}
</script>
