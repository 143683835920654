<template>
    <div class="quiz__loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block; shape-rendering: auto;" width="80px" height="80px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#3F524C" stroke-width="3" r="45" stroke-dasharray="212.05750411731105 72.68583470577035">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.3333333333333333s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>
        <span>{{ loadingText }}</span>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: ['loadingText']
}
</script>
