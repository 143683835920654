<template>
    <template v-if="isDataLoading">
        <loading-spinner :loadingText="loadingText"></loading-spinner>
    </template>

    <template v-if="!isDataLoading">
        <progress-bar 
            :totalCount="progressTotalCount" 
            :current="progressCurrent"
            :questionText="questionText"
        ></progress-bar>

        <div class="quiz__container">
            <div class="quiz__info">
                <div class="quiz__question">{{ question }}</div>
                <div v-if="disable" class="quiz__desc-wrapper">
                    <div class="quiz__desc">{{ description }}</div>
                    <button @click="nextQuestion()" class="btn btn--primary btn--small">
                        {{ nextQuestionText }}
                    </button>
                </div>
            </div>

            <div class="quiz__answers">
                <button v-for="(answer, index) in answers" 
                    :key="index" @click="checkAnswer(answer)" 
                    :class="['btn btn--choice', {'disabled': disable}, addClasses(answer)]"
                >
                    <span>{{ answer.answer }}</span>
                </button>
            </div>

            <div v-if="disable" class="quiz__desc-wrapper">
                <div class="quiz__desc">{{ description }}</div>
                <button @click="nextQuestion()" class="btn btn--primary btn--small">
                    {{ nextQuestionText }}
                </button>
            </div>
        </div>
    </template>
</template>

<script>
import ProgressBar from './progress-bar.vue';
import LoadingSpinner from './loading-spinner.vue';

export default {
    name: 'QuizQuestions',
    components: {
        ProgressBar,
        LoadingSpinner
    },
    props: [
        'question', 
        'answers', 
        'description', 
        'checkAnswer', 
        'nextQuestion', 
        'addClasses', 
        'disable', 
        'progressTotalCount', 
        'progressCurrent', 
        'isDataLoading',
        'nextQuestionText',
        'loadingText',
        'questionText'
    ]
}
</script>
