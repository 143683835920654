<template>
    <div class="grid grid--padding quiz">
        <quiz-result v-if="showResults" 
            :score="quizScore" 
            :total="totalQuestionsCount" 
            :isDataLoading="$apollo.loading" 
            :loadingText="loadingText"
            :tryAgainText="tryAgainText"
            :correctAnswearsText="correctAnswearsText"
            :resultScores="resultScores"
            @reset="resetQuiz"
        >
        </quiz-result>

        <quiz-questions v-if="!showResults" 
            :question="currentQuestion" 
            :answers="currentAnswers" 
            :description="currentDescription"
            :checkAnswer="checkAnswer"
            :nextQuestion="nextQuestion"
            :addClasses="addClasses" 
            :disable="disableButtons"
            :progressTotalCount="totalQuestionsCount" 
            :progressCurrent="activeQuestion"
            :isDataLoading="$apollo.loading"
            :nextQuestionText="nextQuestionText"
            :loadingText="loadingText"
            :questionText="questionText"
        >
        </quiz-questions>
    </div>
</template>

<script>
import { getQuizListing } from './queries/quiz-queries';
import QuizQuestions from './components/quiz-questions.vue';
import QuizResult from './components/quiz-results.vue';

export default {
    name: 'QuizView',
    components: {
        QuizQuestions,
        QuizResult
    },
    props: [
        'nextQuestionText',
        'tryAgainText',
        'loadingText',
        'correctAnswearsText',
        'questionText'
    ],
    data() {
        return {
            error: false,
            getQuizListing: null,
            activeQuestion: 0,
            quizScore: 0,
            disableButtons: false,
            selectedAnswer: null,
            showResults: false
        }
    },
    apollo: {
        getQuizListing: {
            query: getQuizListing,
            variables() {
                return {
                    defaultLanguage: window.location.href.includes('/en/') ? 'en' : 'hr'
                }
            },
            error(error) {
                console.log('errors', error);
                this.error = true;
            }
        }
    },
    computed: {
        quiz() {
            return this.getQuizListing ? this.getQuizListing.edges[0].node : false;
        },
        totalQuestionsCount() {
            return this.getQuizListing ? this.quiz.questions.length : 0;
        },
        currentQuestion() {
            return this.getQuizListing ? this.quiz.questions[this.activeQuestion].Question : false;
        },
        currentAnswers() {
            return this.getQuizListing ? this.quiz.questions[this.activeQuestion].anwsers : false;
        },
        currentDescription() {
            return this.getQuizListing ? this.quiz.questions[this.activeQuestion].Description : false;
        },
        resultScores() {
            return this.getQuizListing ? this.quiz.resultScores : false;
        }
    },
    methods: {
        checkAnswer(answer) {
            this.selectedAnswer = answer;
            this.disableButtons = true;

            this.currentAnswers.forEach(btn => {
                this.addClasses(btn);
            });

            if (answer.correctAnswer) {
                this.quizScore = this.quizScore + 1;
            }

            // setTimeout(() => {
            //     this.selectedAnswer = null;
            //     this.disableButtons = false;

            //     if ((this.activeQuestion + 1) == this.totalQuestionsCount) {
            //         // this.$router.push({name: 'quiz-results'});
            //         this.showResults = true;
            //     } else {
            //         this.activeQuestion = this.activeQuestion + 1;
            //     }
            // }, 3000);
        },
        nextQuestion() {
            this.selectedAnswer = null;
            this.disableButtons = false;

            if ((this.activeQuestion + 1) == this.totalQuestionsCount) {
                this.showResults = true;
            } else {
                this.activeQuestion = this.activeQuestion + 1;
            }
        },
        addClasses(answer) {
            if (this.selectedAnswer == answer && answer.correctAnswer) {
                return 'btn--correct';
            } else if (this.selectedAnswer == answer && !answer.correctAnswer) {
                return 'btn--wrong';
            } else if (this.selectedAnswer && this.selectedAnswer != answer && answer.correctAnswer) {
                return 'btn--correct';
            } else {
                return false;
            }
        },
        resetQuiz() {
            this.activeQuestion = 0;
            this.quizScore = 0;
            this.disableButtons = false;
            this.selectedAnswer = null;
            this.showResults = false;

            // this.$router.push({name: 'quiz-start'});
        }
    },
    mounted() {
        // const appHeight = () => {
        //     document.documentElement.style.setProperty('--app-height', `${(window.innerHeight - 56)}px`)
        // };
        // window.addEventListener('resize', appHeight);
        // appHeight();
    }
}
</script>
