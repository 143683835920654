import { createApp } from 'vue';
import apolloProvider from './apollo.js';
import QuizComponent from './quiz/quiz.vue';

const app = createApp({});
app.use(apolloProvider).component('quiz-component', QuizComponent);
app.mount('#quiz-wrapper');

document.addEventListener('DOMContentLoaded', function() {
    const dropdownToggle = document.querySelector('.header__dropdown-toggle');
    const dropdown = document.querySelector('.header__dropdown');

    dropdownToggle.addEventListener('click', function() {
        dropdown.classList.toggle('active');
    });

    const menuToggle = document.querySelector('.header__menu');
    const menu = document.querySelector('.header__links');

    menuToggle.addEventListener('click', function() {
        menuToggle.classList.toggle('active');
        menu.classList.toggle('active');
    });

    window.addEventListener('click', function(event) {
        if (!event.target.closest('.header__dropdown-toggle')) {
            dropdown.classList.remove('active');
        }

        if (!event.target.closest('.header__menu')) {
            menuToggle.classList.remove('active');
            menu.classList.remove('active');
        }
    });
});
